import { Heading, Text } from "grommet"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 not found" />
    <Heading level="2">404 not found</Heading>
    <Text>nothing here :(</Text>
  </Layout>
)

export default NotFoundPage
